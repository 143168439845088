/**
 * this file contains helper functions explicitely designed for usage in i18next formatting
 * https://www.i18next.com/translation-function/formatting
 */

import i18next from 'i18next'
import { DateTime, Duration, Interval } from 'luxon'
import logger from 'util/logger'

/**
 * this is the entrypoint for formatting which is called by i18next with three arguments.
 * @link https://www.i18next.com/translation-function/formatting
 * @param {*} value the value to be formatted
 * @param {string} format the format identifier
 * @param {string} lng the language
 * @returns the formatted value (if applicable, otherwise the original value)
 */
export const format = (value, format, lng) => {
  try {
    switch (format) {
      /* date and time */
      case 'dateWithWeekdayAndYearAndTime':
        return dateWithWeekdayAndYearAndTime(value, lng)
      case 'dateWithWeekdayAndTime':
        return dateWithWeekdayAndTime(value, lng)
      case 'dateWithWeekday':
        return dateWithWeekday(value, lng)
      case 'dateWithYear':
        return dateWithYear(value, lng)
      case 'relativeDateAndTimeOrDateWithWeekday':
        return relativeDateAndTimeOrDateWithWeekday(value, lng)
      case 'relativeDateOrDateWithWeekdayAndTime':
        return relativeDateOrDateWithWeekdayAndTime(value, lng)
      case 'time':
        return time(value, lng)
      case 'durationFromNow':
        return durationFromNow(value, lng)
      /* interval */
      case 'interval':
        return interval(value, lng)
      /* duration */
      case 'durationInMinutes':
        return durationInMinutes(value, lng)
      case 'durationInDaysHoursOrMinutes':
        return durationInDaysHoursOrMinutes(value, lng)
      case 'durationInDaysHoursOrMinutesShort':
        return durationInDaysHoursOrMinutesShort(value, lng)
        /* distance */
      case 'distanceInKilometersOrMeters':
        return distanceInKilometersOrMeters(value, lng)
      /* price */
      case 'priceInEuro':
        return priceInEuro(value, lng)
      /* other */
      case 'massInKilogram':
        return massInKilogram(value, lng)
      default:
        // in case of unknown format, the original value is returned
        return value
    }
  } catch (e) {
    // if an error is thrown, the original value is returned
    logger.warn(e)
    return value
  }
}

/********************/
/* format functions */
/********************/

/* date and time */

/**
 * applies specific date format using Luxon DateTime.toFormat
 * @link https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#instance-method-toFormat
 * @link https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
 * @param {Date | number} value value (Date object or timestamp) to be formatted
 * @param {string} lng language to be set as locale
 * @returns formatted value
 */
const dateWithWeekdayAndYearAndTime = (value, lng) => {
  return _convertToLuxonDateTime(value, lng).toFormat('ccc, dd.LL.yyyy, HH:mm')
}

/**
 * @see dateWithWeekdayAndYearAndTime
 */
const dateWithWeekdayAndTime = (value, lng) => {
  return _convertToLuxonDateTime(value, lng).toFormat('ccc, dd.LL., HH:mm')
}

/**
 * @see dateWithWeekdayAndYearAndTime
 */
const dateWithWeekday = (value, lng) => {
  return _convertToLuxonDateTime(value, lng).toFormat('ccc, dd.LL.')
}

/**
 * @see dateWithWeekdayAndYearAndTime
 */
const dateWithYear = (value, lng) => {
  return _convertToLuxonDateTime(value, lng).toFormat('dd.LL.yyyy')
}

/**
 * if given value is a datetime for today or tomorrow, the date is formatted as a localized string + formatted time.
 * otherwise falls back to @see dateWithWeekdayAndTime.
 * several functions of Luxon DateTime are used, in particular
 * @link https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#instance-method-hasSame
 * @link https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#instance-method-toRelativeCalendar
 *
 * it was previously implemented in ConnectionRequestForm and handled formatting slightly different,
 * e.g. there was a special case if the datetime was now or less than a minute from now
 * @param {Date | number} value value (Date object or timestamp) to be formatted
 * @param {string} lng language to be set as locale
 * @returns formatted value
 */
const relativeDateOrDateWithWeekdayAndTime = (value, lng) => {
  return relativeDateOrDateWithWeekday(value, lng, true)
}

const relativeDateAndTimeOrDateWithWeekday = (value, lng) => {
  return relativeDateOrDateWithWeekday(value, lng, false)
}

const relativeDateOrDateWithWeekday = (value, lng, needsTime) => {
  const datetime = _convertToLuxonDateTime(value, lng)
  const isToday = datetime.hasSame(DateTime.now(), 'day')
  const isTomorrow = datetime.hasSame(DateTime.now().plus({ days: 1 }), 'day')
  if (isToday || isTomorrow) {
    return `${datetime.toRelativeCalendar({
      unit: 'days',
    })}, ${time(value, lng)}`
  } else if (needsTime) {
    return dateWithWeekdayAndTime(value, lng)
  } else {
    return dateWithWeekday(value, lng)
  }
}

/**
 * @see dateWithWeekdayAndYearAndTime
 */
const time = (value, lng) => {
  return _convertToLuxonDateTime(value, lng).toFormat('HH:mm')
}

/**
 * calculates difference between current and given datetime,
 * then formats that duration using @see durationInDaysHoursOrMinutes and embeds the result in a phrase.
 * @link https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#instance-method-diffNow
 *
 * XXX this implementation is not very elegant:
 * - This function is called `durationFromNow` even though it expects a date or timestamp as value.
 *   It was previously implemented in a similar way as `renderDurationFromNow` in util/time.
 *   The result is actually formatted using durationInDaysHoursOrMinutes
 * - Embedding the formatted value in a phrase requires a nested i18next call with an additional key and context.
 *   We could consider switching over to using Luxon DateTime.toRelative
 *   (https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#instance-method-toRelative)
 *   which would be able to provide standardized phrasing.
 *   However, that may not give us the format preferred by UX,
 *   and may be hard to keep consistent with non date-based durations
 *   since Luxon Duration does not have an equivalent for DateTime.toRelative.
 * @param {Date | number} value value (Date object or timestamp) to be formatted
 * @param {string} lng language to be set as locale
 * @returns formatted value
 */
const durationFromNow = (value, lng) => {
  const duration = _convertToLuxonDateTime(value).diffNow()
  // need to handle negative durations
  const formattedDuration = durationInDaysHoursOrMinutes(Math.abs(duration.as('seconds')), lng)
  return i18next.t('formatting.duration', { value: formattedDuration, context: duration < 0 ? 'past' : 'future' })
}

/* interval */

/**
 * applies interval formatting following this convention:
 * - if start is on same day as now, only display time
 * - if start is on another day than now, display day of week in addition to time
 * - if start is more than one week from now, display date in addition to day of week and time
 * - if end is on same day as start, only display time
 * - if end is on another day than start, display day of week
 *
 * XXX the following cases are currently not handled explicitely:
 * - interval invalid (end before start)
 * - interval partly or completely in past
 * - end is more than one week from start
 *
 * XXX while all other formatting functions are designed for primitive values or native JavaScript such as Date,
 * this one is designed for a Luxon Interval because it requires a well defined single value which describes both start and end
 * so they can be formatted in relation to each other.
 * @link https://moment.github.io/luxon/docs/class/src/interval.js~Interval.html
 *
 * the previous implementation was distributed across ConnectionSummaryMultiPart, ConnectionSummarySinglePart and util/time
 * @param {Interval} value value (Luxon Interval) to be formatted
 * @param {string} lng language to be set as locale
 * @returns formatted value
 */
const interval = (value, lng) => {
  if (value instanceof Interval) {
    const { start, end } = value
    const startWithWeekday = !start.hasSame(DateTime.now(), 'day')
    const endWithWeekday = !end.hasSame(start, 'day')
    const startWithDate = start.diff(DateTime.now()).as('weeks') >= 1
    const startFormat = `${startWithWeekday ? 'ccc, ' : ''}${startWithDate ? 'dd.LL., ' : ''}HH:mm`
    const endFormat = `${endWithWeekday ? 'ccc, ' : ''}HH:mm`
    return `${start.setLocale(lng).toFormat(startFormat)} – ${end.setLocale(lng).toFormat(endFormat)}`
  } else {
    throw new Error(`value ${value} is not a Luxon Interval`)
  }
}

/* duration */

/**
 * applies duration formatting (minutes) using Luxon Duration.toFormat
 * @link https://moment.github.io/luxon/docs/class/src/duration.js~Duration.html#instance-method-toFormat
 * @param {number} value value (in seconds) to be formatted
 * @param {string} lng language to be set as locale
 * @returns the value formatted as duration in minutes
 */
const durationInMinutes = (value, lng) => {
  return _convertToLuxonDuration(value, lng).toFormat('m \'min\'')
}

/**
 * applies duration formatting using Luxon Duration.toFormat following this convention:
 * - durations longer than 1 day in days and hours
 * - durations longer than 1 hour in hours and minutes
 * - everything else in minutes
 * @link https://moment.github.io/luxon/docs/class/src/duration.js~Duration.html#instance-method-toFormat
 * @param {number} value value (in seconds) to be formatted
 * @param {string} lng language to be set as locale
 * @returns the value formatted as duration in days + hours, hours + minutes or just minutes
 */
const durationInDaysHoursOrMinutes = (value, lng) => {
  const d = _convertToLuxonDuration(value, lng)
  if (d.as('days') >= 1) {
    return d.toFormat('d \'d\' h \'h\'')
  } else if (d.as('hours') >= 1) {
    return d.toFormat('h \'h\' m')
  } else {
    return durationInMinutes(value, lng)
  }
}

/**
 * this format is meant to be an even shorter representation of @see durationInDaysHoursOrMinutes.
 * it was previously implemented as a variant of `renderDurationFromSeconds` in util/time.
 * for now, only minutes have a dedicated short format (just the value without unit).
 * if more short formats are required in future, this is the place to add them.
 * until then this function falls back to @see durationInDaysHoursOrMinutes.
 * @param {number} value value (in seconds) to be formatted
 * @param {string} lng language to be set as locale
 * @returns the value formatted as duration in days + hours, hours + minutes or just minutes
 */
const durationInDaysHoursOrMinutesShort = (value, lng) => {
  const d = _convertToLuxonDuration(value, lng)
  if (d.as('hours') < 1) {
    return d.toFormat('m')
  } else {
    return durationInDaysHoursOrMinutes(value, lng)
  }
}

/* distance */

/**
 * applies distance unit formatting to value using JavaScript Number and Intl APIs following this convention:
 * - distances greater than 10 km as km without fraction digits
 * - distances greater than 1 km as km with 1 fraction digit
 * - everything else as m without fraction digits
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * @param {number} value value (in meters) to be converted
 * @param {string} lng language to be set as locale
 * @returns the value formatted as distance in kilometers or meters
 */
const distanceInKilometersOrMeters = (value, lng) => {
  _checkTypeofNumber(value)
  if (value >= 10000) {
    return (value / 1000).toLocaleString(lng, {
      style: 'unit',
      unit: 'kilometer',
      maximumFractionDigits: 0,
    })
  } else if (value >= 1000) {
    return (value / 1000).toLocaleString(lng, {
      style: 'unit',
      unit: 'kilometer',
      maximumFractionDigits: 1,
    })
  } else {
    return value.toLocaleString(lng, {
      style: 'unit',
      unit: 'meter',
      maximumFractionDigits: 0,
    })
  }
}

/* price */

/**
 * applies currency formatting to value using JavaScript Number and Intl APIs.
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * @param {number} value value (in Euro) to be formatted
 * @param {string} lng language to be set as locale
 * @returns the value formatted as price in euro
 */
const priceInEuro = (value, lng) => {
  _checkTypeofNumber(value)
  return value.toLocaleString(lng, {
    style: 'currency',
    currency: 'EUR',
  })
}

/* other */

/**
 * applies unit formatting to value using JavaScript Number and Intl APIs.
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * @param {number} value value (in kilogram) to be formatted
 * @param {string} lng language to be set as locale
 * @returns the value formatted as unit in kilogram
 */
const massInKilogram = (value, lng) => {
  _checkTypeofNumber(value)
  return value.toLocaleString(lng, {
    style: 'unit',
    unit: 'kilogram',
  })
}

/***********/
/* helpers */
/***********/

/**
 * checks if value is a number
 * only exported for unit tests.
 * @param {*} value value to be checked
 * @throws Error if typeof value is not number
 */
export const _checkTypeofNumber = (value) => {
  if (typeof value !== 'number') {
    throw new Error(`value ${value} is not a number`)
  }
}

/**
 * converts value to Luxon DateTime object if possible.
 * only exported for unit tests.
 * @link https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html
 * @param {Date|number} value value to be converted
 * @param {string} lng language to be set as locale
 * @returns converted Luxon DateTime
 * @throws Error if value cannot be converted to Luxon DateTime
 */
export const _convertToLuxonDateTime = (value, lng) => {
  if (value instanceof Date) {
    return DateTime.fromJSDate(value).setLocale(lng)
  } else if (typeof value === 'number') {
    return DateTime.fromMillis(value).setLocale(lng)
  } else {
    throw new Error(`cannot convert value \`${value}\` to Luxon DateTime`)
  }
}

/**
 * converts value (in seconds) to Luxon Duration object if possible.
 * only exported for unit tests.
 * @link https://moment.github.io/luxon/docs/class/src/duration.js~Duration.html
 * @param {number} value value to be converted
 * @param {string} lng language to be set as locale
 * @returns converted Luxon Duration
 * @throws Error if value cannot be converted to Luxon Duration
 */
export const _convertToLuxonDuration = (value, lng) => {
  if (typeof value === 'number') {
    return Duration.fromObject({
      seconds: value,
      locale: lng,
    })
  } else {
    throw new Error(`cannot convert value \`${value}\` to Luxon Duration`)
  }
}
