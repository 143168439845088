/**
 *  checks if the given string corresponds to a feature flag and whether that feature flag is true
 *  (dotenv files cannot provide any other type of variable than string)
 */
export const isEnabled = flagName => {
  if (typeof flagName === 'string') {
    const envValue = process.env['REACT_APP_FF_' + flagName.toUpperCase()]
    return envValue === 'true'
  } else {
    return false
  }
}
