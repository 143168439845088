// add react polyfills according to https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
// already deprecated usage, however seems to be the best working option tm
import 'resize-observer-polyfill/dist/ResizeObserver.global'

import ReactDOM from 'react-dom'

// import roboto font https://next.material-ui.com/components/typography/#install-with-npm
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import './index.css'

import { lazy, Suspense } from 'react'

import AppInformationProvider from './components/context/AppInformationProvider'
import LanguageProvider from './components/context/LanguageProvider'

import { I18nextProvider } from 'react-i18next'
import i18next from 'frameworks/i18next'

import CircularProgress from '@mui/material/CircularProgress'

// Lazyload only used components
const SamsSmap = lazy(() => import('./components/SamsSmap'))
const ConfigSmap = lazy(() => import('./components/ConfigSmap'))

const { pathname } = window.location
const whatsTheSmap = pathname.includes('/v2', 0) ? <SamsSmap /> : <ConfigSmap />

ReactDOM.render(
  <AppInformationProvider>
    <LanguageProvider>
      {/* Initalize early and only once can be overwritten later */}
      <I18nextProvider i18n={i18next()}>
        <Suspense fallback={<CircularProgress color='secondary' />}>
          {whatsTheSmap}
        </Suspense>
      </I18nextProvider>
    </LanguageProvider>
  </AppInformationProvider>
  ,
  document.getElementById('root')
)
