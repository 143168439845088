import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import commonDe from './locales/common_de.json'
import commonDeFormatting from './locales/common_de_formatting.json'
import commonDeRegio from './locales/common_de_regio.json'
import commonEn from './locales/common_en.json'
import commonEnFormatting from './locales/common_en_formatting.json'

import { _shouldLog } from 'util/logger'
import _ from 'lodash'

import { format } from './formatting'

export const i18next = (configResources = {}) => {
  const localResources = {
    de: {
      common: {
        ...commonDe,
        ...commonDeFormatting,
        ...commonDeRegio,
      },
    },
    en: {
      common: {
        ...commonEn,
        ...commonEnFormatting,
      },
    },
  }

  i18n
    // XXX usage of languageDetector will be reevaluated
    // .use(LanguageDetector)
    // pass the i18next instance to the react-i18next components.
    .use(initReactI18next)
    // init i18next
    .init({
      // setting here to prevent console warning because no language detector active
      lng: 'de',
      fallbackLng: 'de',
      debug: _shouldLog(), // Logs infos
      useSuspense: true,
      defaultNS: 'common', // default Namespace
      ns: ['common', 'external'], // Namespace to load
      supportedLngs: ['de', 'en'],
      saveMissing: true, // Saves missing translation keys
      // Local resources
      resources: _.merge(localResources, configResources),

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        // https://www.i18next.com/translation-function/formatting
        format,
      },
      // special options for react-i18next
      // learn more: https://react.i18next.com/latest/i18next-instance
    })

  return i18n
}

export default i18next
