import { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import { isEnabled } from 'util/featureFlags'

export const LanguageContext = createContext()

/**
 * React ContextProvider for language Settings
 * Passes down the value of (@link LanguageContext)
 */
const LanguageProvider = ({ children }) => {
  // Set language based on url query. Gets initially set in state so it doesn't change at runtime
  const [language] = useState(() => {
    if (isEnabled('LANGUAGE_SWITCH')) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      return urlParams.get('lang') || 'de'
    } else {
      return 'de'
    }
  })

  return (
    <LanguageContext.Provider value={language}>
      {children}
    </LanguageContext.Provider>
  )
}

LanguageProvider.propTypes = {
  /* Wrapped component */
  children: PropTypes.node,
}

export default LanguageProvider
