import { useState, createContext } from 'react'
import { useEffectOnce } from 'react-use'
import PropTypes from 'prop-types'

// relative import since base-url is 'src'
import packageJson from '../../../package.json'

/**
 * @type {{environment: string, version: string}} Meta information about the app
 */
export const AppInformationContext = createContext({})

/**
 * HoC to provide context with meta information about the app, such as version and environment.
 * Passes down the value of (@link AppInformationContext)
 */
const AppInformationProvider = ({ children }) => {
  const [appInformation, setAppInformation] = useState(null)

  /**
   * Create app information on mount
   */
  useEffectOnce(() => {
    /* Version */
    const { version } = packageJson
    const build = version

    const hostName = window.location.hostname

    /* Environment */
    let environment
    if (hostName.includes('staging')) {
      environment = 'staging'
    } else if (hostName.includes('smartmobilitymap')) {
      environment = 'live'
    } else {
      environment = 'dev'
    }

    const autodetectPlatformBySubdomains = process.env.REACT_APP_LSD_CONFIG_AUTODETECT_BY_SUBDOMAINS
    const autodetectPlatformBySubdomainsArray = autodetectPlatformBySubdomains.split(',')
    let configPlatformBySubdomain
    // only do this for specific domains
    if (autodetectPlatformBySubdomainsArray.find(d => hostName.includes(d))) {
      configPlatformBySubdomain = hostName.split('.')[0]
    } else {
      configPlatformBySubdomain = null
    }

    const configPlatform = process.env.REACT_APP_LSD_CONFIG_PLATFORM || configPlatformBySubdomain || ''

    setAppInformation({
      version,
      build,
      environment,
      configPlatform,
    })
  })

  const [largeTxt] = useState(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const largeTxtParam = urlParams.get('largeTxt')
    return largeTxtParam === 'true'
  })

  return appInformation && (
    <AppInformationContext.Provider
      value={{
        ...appInformation,
        largeTxt,
      }}
    >
      {children}
    </AppInformationContext.Provider>
  )
}

AppInformationProvider.propTypes = {
  /* Wrapped component */
  children: PropTypes.node,
}

export default AppInformationProvider
